import type { MaybeComputedElementRef } from '@vueuse/core'

export const useAppDisplay = () => {
  const vuetify = useDisplay()

  const isBigScreen = computed(() => {
    return vuetify.xxl.value
  })

  const aspectRatio = computed(() => {
    if (vuetify.mdAndUp.value) return 16 / 6
    if (vuetify.sm.value) return 16 / 8
    return 16 / 12
  })

  return {
    isBigScreen,
    aspectRatio,
  }
}

export const useImageInterval = ({
  target,
  images,
  shuffle = true,
  delay = 1000,
}: {
  images: string[]
  target?: MaybeComputedElementRef
  shuffle?: boolean
  delay?: number
}) => {
  const computedImages = shuffle ? _shuffle(images) : images
  const interval = ref<ReturnType<typeof setTimeout> | undefined>(undefined)
  const currentImage = ref(computedImages[0])

  const makeSetInterval = () => {
    if (computedImages.length <= 1) {
      return
    }

    return setInterval(() => {
      const currentIndex = computedImages.indexOf(currentImage.value)
      const nextIndex = (currentIndex + 1) % computedImages.length
      currentImage.value = computedImages[nextIndex]
    }, delay)
  }

  onBeforeMount(() => {
    interval.value = makeSetInterval()
  })

  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value)
    }
  })

  if (target) {
    const targetIsVisible = useElementVisibility(target)

    watch(targetIsVisible, (isVisible) => {
      if (isVisible) {
        if (interval.value) {
          clearInterval(interval.value)
        }
        interval.value = makeSetInterval()
      } else {
        if (interval.value) {
          clearInterval(interval.value)
        }
      }
    })
  }

  return currentImage
}
