<template>
  <app-sierotki v-slot="{ fix }">
    <p
      v-html="
        fix(
          `ADHD, czyli zespół nadpobudliwości psychoruchowej z deficytem uwagi, jest najczęściej kojarzone z impulsywnością, trudnościami w skupieniu uwagi i nadmierną aktywnością. Przez lata ADHD traktowano głównie jako zaburzenie zachowania i kontroli ruchowej, jednak współczesne badania nad tym zaburzeniem wskazują na znacznie bardziej złożone tło. Dziś ADHD rozumiane jest przede wszystkim jako problem z funkcjami wykonawczymi – czyli zestawem umiejętności odpowiedzialnych za zarządzanie naszym zachowaniem, planowanie, pamięć roboczą, kontrolę impulsów i koncentrację.`,
        )
      "
    />

    <h3 v-html="fix(`Aktywność a funkcje wykonawcze – kluczowe różnice`)" />
    <p
      v-html="
        fix(
          `Na poziomie aktywności ADHD objawia się nadmiernym pobudzeniem, częstością przerw w działaniu, trudnościami w siedzeniu w jednym miejscu czy głośnym, impulsywnym zachowaniem. Właśnie te zachowania są najczęściej zauważane w dzieciństwie, gdy dziecko nie jest w stanie dłużej skupić się na zadaniu lub zachowuje się w sposób nadaktywny.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Jednak w rzeczywistości ADHD to coś więcej niż tylko nadmiar energii. Osoby z tym zaburzeniem zmagają się z trudnościami w funkcjonowaniu ich „wewnętrznego menedżera” – czyli funkcji wykonawczych, które pomagają nam w efektywnym organizowaniu działań. W praktyce oznacza to problemy z planowaniem, kontrolowaniem reakcji emocjonalnych, przełączaniem uwagi między zadaniami i zapamiętywaniem sekwencji działań.`,
        )
      "
    />

    <h3 v-html="fix(`Jak funkcje wykonawcze wpływają na życie codzienne?`)" />
    <p
      v-html="
        fix(
          `Funkcje wykonawcze odgrywają kluczową rolę w codziennym życiu – pomagają zarządzać czasem, organizować zadania, rozwiązywać problemy i podejmować decyzje. Osoba z zaburzeniami w tym obszarze może mieć trudności z kończeniem projektów, z zarządzaniem codziennymi obowiązkami czy z motywacją do realizacji długoterminowych celów. W przypadku ADHD te trudności są często mylone z lenistwem lub brakiem dyscypliny, co dodatkowo utrudnia właściwe zrozumienie potrzeb osoby dotkniętej tym zaburzeniem.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Na przykład osoba dorosła z ADHD może mieć problem z pamiętaniem o spotkaniach lub organizowaniem codziennych obowiązków, takich jak płacenie rachunków. W przypadku dzieci, problemy z funkcjami wykonawczymi mogą przejawiać się w niemożności dokończenia pracy domowej, choć dziecko zaczęło ją z dużym entuzjazmem.`,
        )
      "
    />

    <h3 v-html="fix(`W jaki sposób terapia może pomóc?`)" />
    <p
      v-html="
        fix(
          `Zrozumienie ADHD jako zaburzenia funkcji wykonawczych zmienia podejście do leczenia i wsparcia. Terapia poznawczo-behawioralna oraz specjalne treningi funkcji wykonawczych mogą pomóc osobom z ADHD lepiej organizować swoje działania, zarządzać czasem i nauczyć się technik regulacji emocji. Dla wielu osób kluczowe jest także farmakologiczne wsparcie, które poprawia zdolność do koncentracji i kontrolowania impulsów.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Ponadto, rozwój umiejętności w zakresie funkcji wykonawczych, takich jak techniki zarządzania czasem, ćwiczenia koncentracji i strategie radzenia sobie z emocjami, może znacznie poprawić codzienne funkcjonowanie osoby z ADHD.`,
        )
      "
    />

    <h3 v-html="fix(`ADHD to więcej niż impulsywność i nadaktywność`)" />
    <p
      v-html="
        fix(
          `ADHD to zaburzenie znacznie bardziej złożone, niż sugeruje jego potoczny obraz. Zamiast skupiać się jedynie na „nadmiarze energii”, warto dostrzegać w nim problemy z funkcjami wykonawczymi, które stanowią fundament skutecznego zarządzania naszym życiem. Takie podejście pozwala nie tylko lepiej zrozumieć osoby z ADHD, ale także oferuje im bardziej efektywne wsparcie w codziennym funkcjonowaniu.`,
        )
      "
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
import thumbnail from '@/assets/blog/2024-08-29-adhd-zaburzenie-aktywnosci-czy-funkcji-wykonawczych.jpg'

defineOptions({
  metadata: {
    title: 'ADHD – zaburzenie aktywności czy funkcji wykonawczych?',
    date: '2024-08-29',
    tags: ['adhd'],
    readTime: 9,
    thumbnail,
    description:
      'ADHD, czyli zespół nadpobudliwości psychoruchowej z deficytem uwagi, jest najczęściej kojarzone z impulsywnością, trudnościami w skupieniu uwagi i nadmierną aktywnością. Przez lata ADHD traktowano głównie jako zaburzenie zachowania i kontroli ruchowej, jednak współczesne badania nad tym zaburzeniem wskazują na znacznie bardziej złożone tło.',
  },
})

defineExpose({
  metadata: {
    title: 'ADHD – zaburzenie aktywności czy funkcji wykonawczych?',
    date: '2024-08-29',
    tags: ['adhd'],
    readTime: 9,
    thumbnail,
    description:
      'ADHD, czyli zespół nadpobudliwości psychoruchowej z deficytem uwagi, jest najczęściej kojarzone z impulsywnością, trudnościami w skupieniu uwagi i nadmierną aktywnością. Przez lata ADHD traktowano głównie jako zaburzenie zachowania i kontroli ruchowej, jednak współczesne badania nad tym zaburzeniem wskazują na znacznie bardziej złożone tło.',
  },
})

definePage({
  meta: {
    layout: 'blog-post',
  },
})
</script>
