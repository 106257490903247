<template>
  <v-app-bar class="select-none" color="background" flat floating :height="56">
    <v-container
      class="d-flex align-center py-0"
      :fluid="!appDisplay.isBigScreen.value"
    >
      <app-logo />
      <v-spacer />
      <v-app-bar-nav-icon @click.prevent="drawer = !drawer" />
    </v-container>
  </v-app-bar>
</template>

<script setup lang="ts">
import { useAppDisplay } from '@/lib/style'

const appDisplay = useAppDisplay()
const drawer = inject<boolean>('drawer')
</script>
