<template>
  <app-sierotki v-slot="{ fix }">
    <p
      v-html="
        fix(
          `Pandemia COVID-19 drastycznie zmieniła nasze życie – od zdrowia fizycznego, przez relacje społeczne, aż po kwestie związane z pracą i finansami. Jednak jednym z najważniejszych skutków pandemii, który odczuliśmy wszyscy, jest wpływ na zdrowie psychiczne. Stres związany z niepewnością, izolacją i zmiennością warunków życia doprowadził do wzrostu liczby osób borykających się z problemami takimi jak depresja i lęki.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `W szczególności, zmieniające się restrykcje oraz izolacja wywołały falę samotności i zwiększyły poczucie odłączenia od innych. Pandemia wywołała także nowy fenomen nazwany „zmęczeniem pandemią” – stan chronicznego wyczerpania psychicznego, wywołany długotrwałym funkcjonowaniem w sytuacji niepewności. Objawia się on poczuciem bezsilności i trudnościami w koncentracji, a także obniżonym zainteresowaniem codziennymi obowiązkami.`,
        )
      "
    />
    <p
      v-html="
        fix(
          `Mimo wszystkich trudności pandemia przyniosła jednak także pozytywne zmiany. Zdrowie psychiczne stało się ważnym tematem rozmów publicznych i prywatnych. Ludzie częściej niż wcześniej decydują się na rozmowę z psychologiem lub terapeutą, traktując wsparcie psychologiczne jako normalną formę dbałości o siebie. Wzrosła również popularność terapii online, co uczyniło ją dostępną dla większej liczby osób, które wcześniej mogły mieć ograniczony dostęp do psychoterapii. W efekcie, nasze podejście do zdrowia psychicznego stało się bardziej otwarte i świadome, co daje nadzieję na przyszłość, w której troska o zdrowie psychiczne będzie czymś naturalnym.`,
        )
      "
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
import thumbnail from '@/assets/blog/2024-05-05-efekt-pandemii.jpg'

defineOptions({
  metadata: {
    title:
      '„Efekt pandemii” – jak COVID-19 zmienił nasze podejście do zdrowia psychicznego?',
    date: '2024-05-05',
    tags: [],
    readTime: 3,
    thumbnail,
    description:
      'Pandemia COVID-19 drastycznie zmieniła nasze życie – od zdrowia fizycznego, przez relacje społeczne, aż po kwestie związane z pracą i finansami. Jednak jednym z najważniejszych skutków pandemii, który odczuliśmy wszyscy, jest wpływ na zdrowie psychiczne.',
  },
})

defineExpose({
  metadata: {
    title:
      '„Efekt pandemii” – jak COVID-19 zmienił nasze podejście do zdrowia psychicznego?',
    date: '2024-05-05',
    tags: [],
    readTime: 3,
    thumbnail,
    description:
      'Pandemia COVID-19 drastycznie zmieniła nasze życie – od zdrowia fizycznego, przez relacje społeczne, aż po kwestie związane z pracą i finansami. Jednak jednym z najważniejszych skutków pandemii, który odczuliśmy wszyscy, jest wpływ na zdrowie psychiczne.',
  },
})

definePage({
  meta: {
    layout: 'blog-post',
  },
})
</script>
